// HEADER MODAL // HEADER MODAL // HEADER MODAL // HEADER MODAL

.modal-content-reserve {
  border-radius: 5px !important;
  padding: 0 1rem !important;
}

.modal-header-reserve {
  padding: 0 !important;
  border-bottom: 1px solid #dee2e6!important;

  > .claim-header-inner-modal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 40px 15px 0;

    > .header-title {
      font-weight: 600;
      font-size: 16px;
      font-family: "Work Sans", Bold;
    }

    > div > p {
      color: #8b8b8b;
      font-weight: 500;
      line-height: 1;
      font-size: 11px;
      margin-bottom: 5px;
      font-family: "Work Sans", sans-serif;
    }
  }
}

.modal-body-reserve {
  padding: 0 0 1rem 0 !important;

  .head-section {
    display: flex;
    align-items: center;
    padding: 24px 0px;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;

    > .reserve-status {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 44px;
      margin-left: 0.5rem;

      > .badge {
        background-color: rgba(173,180,188,.164);
        border-radius: .25rem;
        text-align: center;
        font-family: 'Work Sans', sans-serif;
        padding-left: .375rem;
        padding-right: .375rem;
        font-weight: 500;
        font-size: .75rem;
        user-select: none;
      }
    }

    > .left-part {
      display: flex;
      align-items: center;
      flex: 1;

      > .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 44px;
        margin-right: 1rem;

        > .icon-wrapper{
          position: relative;
          background-color: rgba(173,180,188,.164);
          border-radius: 0.5rem;
          width: 44px;
          height: 44px;
          display:flex;
          justify-content: center;
          align-items: center;
          user-select: none;
        }
      }

      .bls-title {
        color: #000000E6;
        font-size: 12px;
        font-family: "Work Sans", Medium;
        font-weight: 600;
      }

      .reserve-date {
        color: #777777;
        font-size: 12px;
        font-family: "Work Sans", Regular;
      }
    }
  }

  .section {
    margin-bottom: 1.5rem;

    .subtitle-reserve {
      font-family: Montserrat-SemiBold, sans-serif;
      font-size: 11px;
      color: black;
      margin-bottom: 0.5rem;
      text-transform: capitalize;
    }

    .text-description-reserve {
      color: #0000008C;
      font-family: "Work Sans";
      font-size: 13px;
      line-height: 18px;
    }

    > .messages {
      margin-top: 1rem;
    }

    > .reserve-comment {
      background-color: #F7F7F8;
      margin-top: 1rem;
      border-radius: 5px;
      width: 100%;
      height: 5rem !important;
      padding: 0.5rem;
      font-size: 13px;
      color: #000000E6;
      font-family: "Work Sans", Medium;
      resize: none;
      border: 1px solid #DFE3E6;
    }

    > .bls-select-container {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      > .bls-selected {
        background-color: #E1F2F3;
        border-radius: 4px;
        color: #29A9A3;
        font-size: 11px;
        font-family: "Work Sans", Regular;
        margin-left: 1rem;
        padding: 3px 6px;
      }
    }
  }
}

// MODAL FOOTER // MODAL FOOTER // MODAL FOOTER // MODAL FOOTER
.modal-reserve-footer {
  padding: 1rem 0 !important;
  border-top: 1px solid #dee2e6!important;
  
  .modal-reserve-footer-body {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    
    .modal-btn-footer {
      background-color: #079992;
      border-radius: 4px;
      border-bottom: 4px solid #0a8a84;
      color: white;
      font-size: 13px;
      padding: 0.5rem;
    }

    .modal-btn-footer:active {
      border-bottom: 1px solid #06726c !important;
    }

    .modal-btn-footer:hover {
      background-color: #10a39c !important;
    }

    .modal-btn-footer:disabled {
      opacity: 0.4;
    }
  }
}

.error {
  color: #e55039;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1;
  min-height: 0.7rem;
}
