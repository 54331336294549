
:root {
  --swiper-navigation-size: 15px;
  --swiper-navigation-color: white;
}

body {
  margin: 0;
  font-family: Montserrat-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(./fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(./fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(./fonts/Montserrat-Medium.ttf);
}
h2, h5{
  font-family: Montserrat-Medium, sans-serif;
}
input:focus,
input:active,
input:focus:active,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}