.background-modal-fleet-config {
  background: url(../../../../../assets/images/background/background_decoration2x.png) no-repeat;
  position: absolute;
  background-size: cover;
  right: -280px;
  top: 0px;
  height: 255px;
  width: 600px;
}

// HEADER // HEADER // HEADER // HEADER

.modal-config-header {
  >.btn-close {
    color: white !important;
    background-color: #079992 !important;
    position: absolute;
    border-radius: 30px;
    top: 30px;
    right: 50px;
    background: transparent url(../../../../../assets/images/optimisation/close-button.png) center/1em auto no-repeat;
    opacity: 1;
  }

  >.claim-header-inner-modal-config {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 0 15px 0;

    >div>h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }

    >div>p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}

// BODY // BODY // BODY // BODY 

.modal-config-body {
  >.modal-span-title {
    font-size: 13px;
    color: #adb8c2;
    font-weight: 500;
  }

  >.modal-input {
    background-color: #eaeaea;
    height: 45px;
    margin-top: 5px;
    width: 100px;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    text-align: center;
  }

  .modal-input::placeholder {
    color: #cbd0d3;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .modal-col-left {
    >.modal-span-title {
      font-size: 13px;
      color: #adb8c2;
      font-weight: 500;
    }

    >.modal-div-background-btn {
      background-color: #eaeaea;
      margin-top: 10px;
      border-radius: 15px;
      height: 53px;
      border: 1px solid #eaeaea;

      >.modal-btn {
        background-color: white;
        width: 169px;
        border-radius: 12px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        margin: 5px 5px 5px 5px;
      }

      >.modal-btn-desactive {
        background-color: transparent;
        width: 169px;
        border-radius: 5px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
        color: #aeb2b4;
        cursor: not-allowed;

        :focus,
        :active {
          outline: none !important;
          box-shadow: none;
        }
      }
    }
  }

  .modal-col-middle {
    >.modal-separator {
      margin-top: 10px;
      height: 50px;
      width: 1px;
      border: 1px #f1f1f1 solid;
    }
  }

  .modal-col-right {
    >.modal-small-text {
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.msg-error-modal-config {
  font-size: 15px;
  color: red;
}

// FOOTER // FOOTER // FOOTER // FOOTER 

.modal-config-footer {
  >.modal-btn-footer {
    height: 60px !important;
    width: 400px !important;
    background-color: #079992 !important;
    border-radius: 11px !important;
    border-bottom: 4px solid #0a8a84 !important;
    color: white !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    text-decoration: underline !important;
    text-decoration-color: #079992 !important;
  }

  >.modal-btn-footer:active {
    border-bottom: 1px solid #06726c !important;
  }

  >.modal-btn-footer:hover {
    background-color: #10a39c !important;
  }

  >.modal-btn-footer:disabled {
    opacity: 0.4;
  }
}