.fleet-item-steps-container {
  display: block;
}
.fleet-item-step-no-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  min-width: 608px;
  position: relative;
  margin-left: 200px;
  overflow: hidden;
}
.fleet-item-step-no-item > p {
  font-weight: 500;
  color: #dadada;
}
.fleet-item-step {
  min-width: 608px;
  padding: 10px;
  position: relative;
  margin-top: 10px;
  border-radius: 5px;
  margin-left: 80px;
  height: 90px;
  background-color: #f6f8fa;
  display: flex;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: box-shadow 0.2s;
}
.fleet-item-step.invalid {
  opacity: 0.4;
  cursor: not-allowed;
}
.fleet-item-step.invalid-CAL {
  opacity: 0.4;
}
.fleet-item-step:hover {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
}
.fleet-item-step.search-match {
  border-color: #079992;
  outline: none;
  border-width: 0.15rem;
  transition: all 0.1s ease-in-out;
  border-style: solid;
  /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 1%),
    0 0 6px #07999279; */
}
.fleet-item-step > .fleet-first-line > .hwe {
  flex: 0 0 60px;
}
.hwe-child-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fleet-item-step > .fleet-first-line > .hwe > .hwe-child-wrapper > span {
  font-family: Montserrat-SemiBold, sans-serif;
  line-height: 40px;
  flex-grow: 1;
  font-size: 40px;
  color: #079992;
}
.fleet-item-step > .fleet-first-line > .hwe > .hwe-child-wrapper > div {
}
.fleet-item-step > .fleet-first-line > .hwe > .hwe-child-wrapper > div > img {
  height: 17px;
}
body .fleet-item-step.active {
  color: white;
  background-color: #079992;
}
.fleet-item-step.active > .fleet-first-line > .fr > .fleet-item-title {
  color: white;
}
.fleet-item-step.active > .fleet-first-line > .fr > .fleet-item-desc {
  color: #dbdbdb;
}
.fleet-item-step.active .fleet-item-title,
.fleet-item-step.active .fleet-item-desc,
.fleet-item-step.active .fleet-item-arrival {
  color: white;
}
.fleet-item-step.active > .fleet-first-line > .hwe > .hwe-child-wrapper > span,
.fleet-item-step.active .fleet-item-status {
  color: #dbdbdb;
}
.fleet-item-step.active .fleet-item-status::after,
.fleet-item-step.active .fleet-item-status::before {
  background-color: #dbdbdb !important;
}

body .header-tour-cat {
  color: #A8A8A8;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 12px;
  background-color: #DBDBDB;
  border-radius: 5px;
  padding: 10px 5px;
  width: 120px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.fleet-item-bl {
  min-width: 608px;
  position: relative;
  margin-top: 10px;
  margin-left: 200px;
  text-align: left;
  display: block;
  overflow: hidden;
}
.fleet-item-bl h5 {
}
.fleet-item-bl-container {
  margin-left: 15px;
}
.fleet-item-bl-item {
  display: flex;
  flex-direction: row;
}
.fleet-item-bl-item-title {
  flex-grow: 2;
}
.fleet-item-bl-item-qtt {
  font-size: 20px;
  font-family: Montserrat-Bold, sans-serif;
}
body .fleet-item-btn-seemore {
  border-width: 2px;
  border-style: solid;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 9px 40px;
  font-family: Montserrat-Bold, sans-serif;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-color: #079992;
  font-size: 1rem;
  line-height: 1.5;
  color: #079992;
}
body .fleet-item-btn-seemore:hover {
  border-color: #07837d;
  color: #07837d;
}
body .fleet-item-btn-seemore:active {
  transform: scale(0.95);
}

body .fleet-search-tour {
  width: 250px;
  height: 45px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  transition: all 0.1s ease-in-out;
  padding-left: 0.75rem;
  padding-right: 1.5rem;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: #A4A9B4;
  border-width: 1px;
  border-radius: 8px;
  border-color: #CED7E1;
  border-style: solid;
  outline-offset: -2px;
  line-height: 1.15;
  margin: 20px 0 20px 0;
}
body .fleet-search-tour:focus {
  border-color: #079992;
  outline: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 1%), 0 0 8px rgb(34 139 230 / 50%);
  transition: all 0.1s ease-in-out;
}
body .fleet-search-tour:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.search-bar-wrapper {
  position: relative;
}
.spinner-search {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 20px;
}

body .btn-fleet-header {
  padding: 10px 16px;
  border-radius: 10px;
  background-color: #f7f7f9;
  font-weight: 600;
  font-size: 13px;
}

body .btn-fleet-header:focus {
  box-shadow: none;
}

body .btn-fleet-header:hover {
  background-color: #dbdbdf;
}

body .btn-fleet-header > .btn-icon {
  margin-right: 13px;
}

body .fleet-header-drowpdown-item {
  padding: 3px 16px;
  font-weight: 600;
  font-size: 13px;
}

.fleet-item > .fleet-first-line-tour > .fr > .fleet-item-title,
.fleet-item-step > .fleet-first-line > .fr > .fleet-item-title {
  white-space: nowrap;
  text-align: left;
  display: block;
  font-size: 12px;
  font-weight: 700;
  color: #334645;
}

.fleet-item > .fleet-first-line-tour > .fr > .fleet-item-desc,
.fleet-item-step > .fleet-first-line > .fr > .fleet-item-desc {
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #A4A9B4;
}

.fleet-item > .fleet-first-line-tour > .fr > .fleet-item-desc-light,
.fleet-item-step > .fleet-first-line > .fr > .fleet-item-desc-light {
  position: relative;
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  z-index: 0;
}
