body .fleet-item-bl-number.disabled-bl {
  color: #bababa !important;
}

.picto-wrapper-bl {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
}
.picto-wrapper-bl div {
  display: flex;
  flex-direction: row;
  margin-left: 9px;
}
.picto-wrapper-bl div img {
  height: 20px;
}
body .fleet-item-bl-card {
  border: none;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px !important;
  margin-bottom: 9px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
}
body .fleet-item-bl-card:hover {
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05);
}

body .fleet-item-bl-wrapper {
  display: flex;
  flex-direction: row;
}

.fleet-item-bl-images {
  position: relative;
  height: 60px;
  width: 60px;
  overflow: hidden;
  margin-right: 20px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 7px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
}
.fleet-item-bl-image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.fleet-item-bl-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.fleet-item-bl-image a {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  display: block;
}
.fleet-item-bl-image a img {
  height: auto;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
