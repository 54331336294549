.message-container {
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid lightgrey;
  background-color: #F7F7F8;
  display: flex;
  align-items: center;

  > .text {
    font-size: 11px;
    color: #00000080;
    font-family: MontSerrat, Medium;
    font-weight: 600;
  }

  > .square {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 1rem;
    flex-shrink: 0;

    &.digitappro {
      background-color: #fbc900;
    }

    &.fleet {
      background-color: #f84d42;
    }

    &.response {
      background-color: #079992;
    }
  }
}