.fleet-item-upload{
    position: relative;
    display: flex;
    display:block;
    background: white;
    border-radius:25px;
    margin:10px;
    padding: 40px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    overflow: hidden;
    min-width: 600px;
    text-align:center;
}
.upload-inner{
    height: 300px;
    width:100%;
    border: 3px dashed #e2e2e2;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    transition: all .2s;
    justify-content: center;
}
.img-illustration-fleet-upload{
    height:110px;
    width:auto;
}
.upload-inner:hover{
    background-color: rgba(96, 182, 255, 0.34);
    border: 3px dashed rgba(85, 124, 255, 0.85)
}
.fleet-click-label-input{
    font-weight: bold;
    cursor:pointer;
    color: rgba(85, 124, 255, 0.85);
}
.fleet-click-label-input:hover{
    text-decoration: underline;
}
.upload-inner-inprogress{
    position:relative;
    margin-top:20px;
    width:100%;
    border: 2px solid #e2e2e2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    transition: all .2s;
    padding:10px;
    text-align:left;
    
}
.upload-inner-inprogress-bg{
    width:80%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    background-color: rgba(96, 182, 255, 0.34);

}
.upload-inner-inprogress-progressbar{
    border: 2px solid #557cff;
    width:80%;

}