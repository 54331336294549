.log-container {
  width: 100%;
  padding: 5px 10px;
  border-bottom: 1px solid lightgrey;

  > .text {
    font-size: 11px;
    color: #00000080;
    font-family: MontSerrat, Medium;
    font-weight: 600;
  }
}