.fleet-item>.no-item{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: start;
    align-items:center;
    padding: 10px;
}
.fleet-item>.no-item > img{
    width:auto;
    height:80px;
}
.fleet-item > .no-item > .title-no-item{
    padding:5px 5px 0px 5px;
    font-size:18px;
    font-weight:bold;

}
.fleet-item > .no-item > .desc-no-item{
        font-size:14px;
        margin-bottom: 20px;
    color: #6b6b6b;
}