body .fleet-item-bl-number.disabled-bl{
    color: #bababa !important;
}
.fleet-item-bl-number-wrap{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}

.picto-wrapper-bl{
    display:flex;
    flex-direction: row;
    position:absolute;
    top:.75rem;
    right:1.25rem;
}
.picto-wrapper-bl div{
    display:flex;
    flex-direction: row;
    margin-left:9px;
}
.picto-wrapper-bl div img{
    height: 20px;
}
body .accordion .fleet-item-bl-card-ter{
    padding:5px;
    box-shadow:none;
}
body .accordion .fleet-item-bl-card-ter:hover{
    box-shadow:none;
}
.fleet-item-bl-card-ter-use{
    font-family: Montserrat-SemiBold, sans-serif;
    font-weight: 300;
    font-size:12px;
    color: #0D0D0D;
}

body .fleet-item-bl-wrapper{
    display:flex;
    flex-direction: row;
    font-weight: 400;
}

.fleet-item-bl-images{
    position:relative;
    height: 60px;
    width:60px;
    overflow:hidden;
    margin-right:20px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 7px;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.03);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.03);
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.03);
}
.fleet-item-bl-image-container{
    overflow:hidden;
    width:100%;
    height:100%;
    position:relative;
}
.fleet-item-bl-image{
    width:100%;
    height:100%;
    position:relative;
}
.fleet-item-bl-image a{
    background-color: rgb(255, 255, 255);
    width:100%;
    height:100%;
    display:block;
}
.fleet-item-bl-image a img{
    height: auto;
    position: absolute;
    width: 100%;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
}