.select-container {
  width: 100%;
  background-color: #ECEEF0;
  border-radius: 10px;
  border: 1px solid #DFE3E6;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  cursor: pointer;

  > .selected-value {
    height: 100%;
    width: fit-content;
    background-color: #079992;
    color: #F6F6F6;
    font-size: 12px;
    font-family: "Work Sans", Regular;
    padding: 4px 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2px;
    cursor: default;
  }

  > .pick-bl-text {
    font-size: 11px;
    color: #0000008C;
    font-family: "Work Sans", Regular;
  }
}

.select-choices {
  position: absolute;
  max-height: 150px;
  width: 100%;
  background-color: white;
  border: 1px solid #CDCDCA;
  border-radius: 5px;
  top: 100%;
  padding: 6px;
  overflow-y: auto;

  > .choice {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;

    &.checked {
      background-color: #EFEFEF;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &:active {
      background-color: #dedede;
    }

    &.first {
      margin-top: 0;
    }

    > .checkbox {
      cursor: pointer;
      margin-right: 0.5rem;
      accent-color: #079992;
    }

    > .text {
      color: #000000E6;
      font-size: 12px;
      font-family: "Work Sans", Regular;
      font-weight: 500;
    }
  }
}