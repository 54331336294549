.fleet-status-component-wrapper {
  position: relative;
  display: flex;
  z-index: 0;
  align-items: center;
}
.status-text {
  white-space: nowrap;
  position: relative;
  font-size: 11px;
  font-weight: 600;
  margin-left: 9px;
}
@media screen and (max-width: 1000px) {
  .status-text{
    max-width: 0;
    margin-left: 9px;
    color: transparent;
  }
}

.status-wrapper{
  position:relative;

}
.status-background {
  display:block;
  opacity: 0.3;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: #079992;
  transform-origin: 50% 0 0;
  z-index: 0;
}
.status-bullet {
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: green;
  z-index: 1;
}

.status-background.ok,
.status-bullet.ok {
  background-color: #079992;
}

.status-background.late,
.status-bullet.late {
  background-color: orange !important;
}

.status-background.issue,
.status-bullet.issue {
  background-color: #ff1400;
}
#button-tooltip-2 {
  font-size:11px;
}
#button-tooltip-2 > .tooltip-inner {
    background-color: #f7f7f9;
    color:rgb(92, 92, 92);
    font-weight: 500;

}
#button-tooltip-2 .tooltip-arrow::before {
  border-top-color: #f7f7f9;
}