.fleet-item-bl {
  font-family: Montserrat-Medium, sans-serif;
}
.card-loader-5 {
  border: none !important;
}
.fleet-item-bl-number {
  font-family: Montserrat-Bold, sans-serif;
  position: relative;
  color: #000000;
}
.fleet-item-bl-list-item {
  margin-left: 15px;
}
body .fleet-item-bl-list-item > .card {
  border: none;
}
body .card-header-no-css {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: none;
}
.fleet-item-information-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.fleet-item-information-container img {
  height: 30px;
  width: auto;
}
.fleet-item-information-container span {
  color: #b1b1b1;
  margin-left: 10px;
  font-size: 15px;
}
/***
    Step background color
 ***/
.fleet-item-step.late {
  background-color: #ffe085;
}
.fleet-item-step.issue {
  background-color: #ff9979;
}
