.fleet-item-upload-container {
  position: relative;

  .bg-deco-fleet {
    display: none;
    position: absolute;
    top: -10%;
    right: 0%;
    width: auto;
    z-index: 0;
  }
}

.fleet-item-upload {
  position: relative;
  display: flex;
  display: block;
  background: white;
  border-radius: 25px;
  margin: 10px;
  padding: 20px 40px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 600px;
  text-align: center;
  transition: opacity 0.1s ease-in-out;
  max-height: calc(100vh - 40px);

  .upload-header-inner {
    text-align: left;
    margin-bottom: 25px;

    >h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }

    >p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .upload-main-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: unset;

    .left-part {
      position: relative;
      flex: 1 1 65%;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 150px);

      >.upload-header-inner {
        position: relative;
        z-index: 1;
      }

      >.bg-deco-fleet {
        position: absolute;
        top: 0;
        right: -23%;
        height: 660px;
        width: auto;
        z-index: 0;
        user-select: none;
      }

      >.upload-select-simulation-type-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;

        >.upload-select-simulation-type {
          background-color: #079992;
          border-radius: 20px;
          display: block;
          padding: 3px;

          >.btn-simulation {
            font-weight: 400;
            user-select: none;
            text-align: center;
            height: 65px;
            padding: 0 50px;
            width: auto;
            background: white;
            border: 1px solid transparent;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            color: #079992;

            >.btn-simulation-title {
              display: block;
              font-size: 14px;
              font-weight: 700;
              line-height: 1;
              margin-bottom: 4px;
            }

            >.btn-simulation-desc {
              display: block;
              padding: 0.5em 0.5em;
              background-color: #079992;
              font-size: 12px;
              font-weight: 600;
              color: white;
              border-radius: 6px;
              line-height: 1;
            }
          }
        }
      }

      >.tour-upload-item-wrapper {
        position: relative;
        z-index: 1;
        min-height: 200px;
        width: 100%;
        margin-bottom: 25px;
        overflow-y: auto;
      }

      >.tour-upload-button {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-bottom: 25px;

        >.button-confirm {
          height: 65px;
          width: 100%;
          background-color: #079992;
          border-radius: 15px;
          border-bottom: 4px solid #0a8a84;
          color: white;
          font-weight: 500;
          font-size: 15px;

          &:hover {
            background-color: #10a39c;
          }

          &:active {
            border-bottom: 1px solid #06726c;
          }
        }
      }
    }

    .right-part {
      flex: 0 0 35%;
      max-height: calc(100vh - 150px);
      padding-left: 20px;

      .map-wrapper {
        display: block;
        //padding: 0px 20px;
        position: relative;
        height: 100%;
        min-width: 379px;
        min-height: 200px;

        .map {
          display: block;
          height: 100%;
          max-height: 100%;
          background: rgb(219, 219, 219);
          border-radius: 15px;
          overflow: hidden;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
          position: relative;

          .map-overlay {
            position: absolute;
            bottom: 15px;
            left: 50%;

            max-width: calc(100% - 40px);
            transform: translateX(-50%);

            .map-overlay-inner {
              padding: 10px 20px;
              background: white;
              border-radius: 25px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              flex-wrap: nowrap;
              align-items: center;

              .overlay-kpi {
                flex: 0 1 auto;
                padding: 15px;

                & div:first-child {
                  text-align: center;
                  color: #079992;
                  font-weight: 700;
                  font-size: 25px;
                }

                & div:nth-child(2) {
                  color: black;
                  font-weight: 500;
                  font-size: 10px;
                  text-align: center;
                }
              }

              .ant-divider-vertical {
                flex: 0 0 auto;
                position: relative;
                top: -0.06em;
                display: inline-block;
                height: 0.9em;
                margin: 0 8px;
                vertical-align: middle;
                border-top: 0;
                border-left: 1px solid rgba(0, 0, 0, 0.06);
              }
            }
          }
        }
      }
    }
  }

  .upload-inner {
    height: 200px;
    width: 100%;
    border: 3px dashed #e2e2e2;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    justify-content: center;

    >div>.badge-file-type {
      margin-top: 7px;
      background-color: #079992;
      padding: 2px 15px;
      display: inline-block;
      font-size: 10px;
      font-weight: bold;
      color: white;
      border-radius: 10px;
    }

    >.fleet-upload-text-label {
      font-size: 0.9rem;
      font-weight: 500;

      .fleet-click-label-input {
        font-weight: 600;
        cursor: pointer;
        color: rgba(85, 124, 255, 0.85);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .img-illustration-fleet-upload {
      height: 60px;
      width: auto;
      margin-bottom: 10px;
    }

    &:hover {
      background-color: rgba(96, 182, 255, 0.34);
      border: 3px dashed rgba(85, 124, 255, 0.85);
    }
  }

  .upload-inner-inprogress {
    position: relative;
    margin-top: 20px;
    width: 100%;
    border: 2px solid #e2e2e2;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    padding: 10px;
    text-align: left;

    >h5 {
      font-weight: 500;
      line-height: 1;
      margin-bottom: 15px;
      font-size: 0.9rem;
    }

    >.progress>.progress-bar {
      background-color: #079992;
    }
  }

  .upload-inner-inprogress-bg {
    width: 80%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(96, 182, 255, 0.34);
  }

  .upload-inner-inprogress-progressbar {
    border: 2px solid #557cff;
    width: 80%;
  }

  .upload-button-confirm-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;

    .button-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex: 0 1 50%;

      .button-confirm {
        height: 65px;
        width: 100%;
        background-color: #079992;
        border-radius: 15px;
        border-bottom: 4px solid #0a8a84;
        color: white;
        font-weight: 500;
        font-size: 15px;
        padding: 0 15px;
        margin-left: 15px;
        white-space: nowrap;

        &:hover {
          background-color: #10a39c;
        }

        &:active {
          border-bottom: 1px solid #06726c;
        }
      }

      .button-back {
        height: 65px;
        width: 100%;
        border-radius: 15px;
        border: 2px solid #0a8a84;
        color: #0a8a84;
        font-weight: 500;
        font-size: 15px;
        white-space: nowrap;
      }
    }
  }
}

.btn-opti {
  background-color: #079992;
}

.fleet-tooltip-opti {
  margin-right: 10px;
}