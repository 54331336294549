.page-wrapper {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 2100px;
}

.tour-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 auto;
  padding: 5px 20px 5px 0;

  > .tour-header-left {
    position: relative;
    display: flex;
    align-items: center;
    > .tour-header-title {
      font-weight: bold;
      line-height: 1;
      font-size: 18px;
    }
    > .tour-header-number {
      color: #fff;
      background-color: #079992 !important;
      padding: 7px 5px;
      margin-left: 10px;
    }
  }
  > .tour-header-right {
    > .tour-select {
      padding: 6px 10px;
      border-radius: 10px;
      background-color: #f7f7f9;
      border: none;
      font-weight: 700;
      font-size: 13px;
    }
  }
}
.content-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 1 auto;
  position: relative;
  min-height: 0;
  > .content-left-part {
    flex: 0 0 50%;
    // height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    max-height: 100%;
    min-width: 385px;
    max-width: 840px;

    > .tours-container {
      display: block;
      flex-grow: 1;
      padding: 20px 10px 20px 10px;
      overflow-y: hidden;
      position: relative;
      min-height: 0;
      height: 100%;
      width: 100%;
      margin-bottom: -15px;
      > .wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    > .kpis-container {
      display: flex;
      flex-direction: row;
      margin: 0 -5px;
      padding: 20px 20px 20px 0;
      flex: 0 0 auto;
      @media screen and (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
  }
  > .content-right-part {
    flex: 0 0 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 40px;
    > .map-part {
      flex: 0 0 50%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      > .map-div-container {
        padding: 15px;
        border-radius: 27px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
        height: 100%;
        margin-top: 22px;
        position: relative;
        display: flex;

        > .map-dashboard-container {
          flex: 1 1 auto;
          min-width: 0;

          background-color: #f6f6f4;
          border-radius: 27px;
          height: 100%;
          width: 100%;
          position: relative;
          .map-wrapper {
            border-radius: 27px;
          }
        }
        > .step-info-dashboard-container {
          flex: 0 0 158px;
          position: relative;
          min-width: 0;
          max-width: 0;
          overflow: hidden;
          transition: max-width 0.2s;
          &.open {
            width: 158px;
            max-width: 200px;
          }
          .step-info-dashboard-container-inner {
            padding: 10px 0 10px 10px;

            height: 100%;
            width: 158px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .step-info-header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .logo-container-feed {
                background: #c4e7e5;
                display: flex;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                height: 25px;
                width: 25px;
                flex: 0 0 25px;
                .icon-step-detail {
                  height: 13px;
                  width: 13px;
                  color: #079992;
                }
              }
              > .step-info-desc {
                font-size: 10px;
                font-weight: 500;
                line-height: 1;
                color: #b5b5b5;
                margin-top: 10px;
              }
              > .step-info-step-name {
                margin-top: 7px;
                text-align: center;
                font-size: 12px;
                color: #434343;
                // padding-top: 15px;
                font-weight: 700 !important;

                margin-bottom: 5px;
                font-weight: bold;
                line-height: 1;
              }
            }
            > .step-info-progression {
              width: 80px;
              .CircularProgressbar-path {
                stroke: #079992;
              }
              .step-info-circular-wrapper {
                width: auto !important;
                text-align: center;
                strong {
                  display: block;
                  margin: 0 auto;
                }
              }
            }
            > .step-info-call-to-action-wrapper {
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              text-decoration: none;
              padding: 5px;
              border-radius: 4px;
              &:hover {
                > .call-to-action-logo {
                  background: #c4e7e5cc;
                }
                > .call-to-action {
                  color: #585757 !important;
                }
              }

              > .call-to-action {
                font-size: 10px;
                font-weight: bold;
                padding-right: 5px;
                text-decoration: none;
                color: black;
              }
              > .call-to-action-logo {
                background: #c4e7e5;
                display: flex;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                height: 25px;
                width: 25px;
                flex: 0 0 25px;
                .icon-step-detail {
                  height: 13px;
                  width: 13px;
                  color: #079992;
                }
              }
            }
          }
        }
      }
    }
    > .feed-part {
      flex: 0 0 50%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      min-height: 0;
      > .feed-div-container {
        padding: 20px;
        border-radius: 27px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
        height: 100%;
        margin-top: 22px;
        position: relative;
        min-height: 0;
        > .feed-dashboard-container {
          overflow: hidden;
          height: 100%;
          width: 100%;
        }

        > .map-dashboard-container {
          background-color: #f6f6f4;
          border-radius: 27px;
          height: 100%;
          width: 100%;
          position: relative;
          .map-wrapper {
            border-radius: 27px;
          }
        }
      }
      > .feed-container {
        height: 100%;
        border: 1px solid blue;

        > .feed-inner {
          height: 100%;
          border: 1px solid green;
        }
      }
    }
  }
}

.fade-enter div {
  overflow-y: hidden;
  max-height: 0;
}

.fade-enter-active div {
  max-height: 50px;
  transition: all 500ms ease-in;
}
.fade-exit div {
  max-height: 50px;
}
.fade-exit-active div {
  max-height: 0;
  overflow-y: hidden;
  transition: all 500ms ease-in;
}

.live-signal {
  position: relative;
  content: "";
  opacity: 0.7;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #eb2f06;
  margin-left: 9px;
  &:before {
    opacity: 0.3;
    position: absolute;
    content: "";
    top: 50%;
    left: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #eb2f06;
    transform-origin: 50% 0 0;
  }
}

.mapboxgl-popup-content {
  border-radius: 15px;
  padding: 15px 20px;
  box-shadow: 1px 1px 29px rgba(100, 100, 111, 0.2);
  .mapboxgl-popup-close-button {
    border-radius: 0 12px 0 12px !important;
  }
  .desc-card {
    .desc-card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      > .title {
        display: inline-block;
        font-size: 14px;
        color: #434343;
        // padding-top: 15px;
        font-weight: 700 !important;

        margin-bottom: 5px;
        font-weight: bold;
        line-height: 1;
      }
      > .status {
        display: inline-block;
        font-size: 11px;
        font-weight: 500 !important;
        padding: 3px 11px;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: #a5dad8b0;
        color: #079992;
        // text-transform: capitalize !important;
        margin-left: 10px;
        border-radius: 15px;
        line-height: 1;
        &.ok {
          background-color: #e4e4e4;
          color: #878c92;
        }
        &.started {
          background-color: #c4e7e5;
          color: #079992;
        }
        &.late {
          color: orange !important;
          background-color: rgba(255, 166, 0, 0.151) !important;
        }
        &.issue {
          color: #ff1400 !important;
          background-color: #ff150041 !important;
        }
      }
    }
    > .desc-time {
      color: #b1b1b1 !important;
      text-transform: none !important;
      font-size: 11px;
      font-weight: normal;
      margin: 0;
      line-height: 1;
    }
    > .button-wrapper {
      position: relative;
      > .go-to-tour {
        position: relative;
        padding: 5px 10px;
        border-radius: 10px;
        margin-top: 32px;
        background-color: #a5dad8b0;
        color: #079992;
        font-weight: 600;
        font-size: 12px;
        width: 100%;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.circle-marker {
  background-color: #079992;
  border: 3px solid #fff;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.137);
  cursor: pointer;
  &.delivered {
    opacity: 1;
    // opacity: 0.35;
  }
  &.started {
    animation: status-started-blink infinite 0.8s ease alternate;
  }

  .circle-marker-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    color: white;
    margin: 0;
    font-size: 13px;
    font-weight: bold;
  }
}

.imported-date {
  font-size: 13px;
  text-align: center;
}

@keyframes status-started-blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}
