@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');


/* header login */
.header-login {
  margin-bottom: 60px;
}

.header-login>h2 {
  text-align: left;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 82px;
  font-weight: bold;
}

.header-login>h5 {
  font-family: Montserrat-Medium, sans-serif;
  text-align: left;
  font-size: 30px;
}

.error-message-container {

  padding: 10px;
}

.error-message-container>.error-message {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.welcome {
  color: black;
  font-weight: 900;
  font-size: 31px;
}

.container-form {
  padding-left: 100px;
}

@media screen and (max-width: 1308px) {
  .container-form {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1118px) {
  .container-form {
    padding-left: 10px;
  }
}

.label {
  /* color: #878F9C; */
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  letter-spacing: -0.6px;
  margin-bottom: 11px !important;
}

.label-2 {
  /* color: #878F9C; */
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  letter-spacing: -0.6px;
}

.custom-input-login {
  height: 40px !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px !important;
}

.custom-input-login::placeholder {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #878F9C;
}

@media screen and (max-width: 1550px) {
  .custom-input-login {
    width: 400px !important;
  }
}

.password-forget {
  /* color: #878F9C; */
  color: white;
  text-decoration: underline;
  text-decoration-color: #878F9C;
  font-size: 14px;
}

.btn-login {
  height: 37px !important;
  width: 158px !important;
  background-color:  white!important;
  border-radius: 5px !important;
  border-bottom: 4px solid white !important;
  color: #079992 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  text-decoration: underline !important;
  text-decoration-color: #079992 !important;
}

btn-login:active {
  border-bottom: 1px solid #06726c !important;
}

btn-login:hover {
  background-color: #10a39c !important;
}

.background-img-container {
  overflow: visible;
  position: relative;
}

.background-img {
  // background: url("/src/assets/images/background/background_login.png") no-repeat;
  background-position: center;
  background-size: fill;
  height: 100%;
  width: 100%;
}

.logo-login {
  object-fit: contain;
  height: 200px;
  width: 300px;
}

.error-msg-login {
  color: rgb(229, 80, 57);
  font-size: 13px;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
}

.RowTitle{
  color: #079992;
  font-size: 28px;
  font-weight: 600;
  min-width: 300px;
  margin-bottom: 50px;
}
.title{
  font-family: 'Work Sans';
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;
  color: #06726c;
}
.iconLarge{
  color: #10a39c;
  font-size: 30px;
  margin-right: 20px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  height: 40px;
  width: 40px !important;
}
.headerContainer{
  display: flex;
  align-items: center;
}
.bigHeader{
  background-position: bottom;
  background-size: cover;
  overflow: hidden;
  padding: 30px;
  // margin: 0px;
  min-height: 700px;
  color: white;
}
.mainTitle{
  font-size: 45px;
  text-transform: uppercase;
  line-height: 50px;
  padding: 60px 0px;
  font-family: 'Work Sans';
  font-weight: 700;
}

.coloredBanner{
  background-color: rgba($color: #10a39c, $alpha: 0.2);
  border-radius: 5px;
  padding-right: 50px;
}

.bigTitle{
  font-size: 35px;
  color: #06726c;
  font-family: 'Work Sans';
  font-weight: 700;
  margin-bottom: 20px;
}

.lightBtn{
  background-color: white;
  color: #079992;
  border: lightgray 1px solid;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  &:hover{
    background-color: #f9f9f9;
    cursor: pointer;
  }
}

.container{
  font-size: 12px;
}
.imgRow{
  align-items: center;
  .carou{
    max-width: 100%;
    max-height: 600px;
  }
}

.mapMarker{
  &:hover{
    transform: scale(1.1);
    transition: ease-in-out;
    transition-duration: 200ms;
  }
}

.footer{
  background-color: #10a39c;
  padding: 50px 0px;
  margin-top: 80px;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  a{
    color: white;
  }
  .brandRow{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .img{
      margin: 0px 20px;
    }
  }
}

