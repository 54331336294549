.tchat-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 1em 0 0 0;

  .messageContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0.5em 0;

    > div:nth-child(1) {
      flex: 0 0 20%;
      display: flex;
      & > span {
        color: rgba(123, 123, 123, 0.9);
        font-size: 11px;
        line-height: 11px;
        font-weight: 500;
      }
    }
    .message {
      border-radius: 10px;
      width: 100%;
      position: relative;
      font-weight: 500;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.9);
      font-size: 12px;
      font-family: "Work Sans", sans-serif;
    }

    .date {
      text-align: left;
      color: rgba(123, 123, 123, 0.9);
      font-size: 11px;
      font-weight: 500;
      margin-top: 14px;
    }

    div:nth-child(2) {
      flex: 1 1 auto;
      & > span {
        color: rgba(123, 123, 123, 0.9);
        font-size: 11px;
        font-weight: 500;
      }
    }
  }
}
