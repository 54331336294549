.background-modal-fleet-add-platform {
  background: url(../../../../../assets/images/background/background_decoration2x.png) no-repeat;
  position: absolute;
  background-size: cover;
  right: -280px;
  top: 0px;
  height: 255px;
  width: 600px;
}

// HEADER // HEADER // HEADER // HEADER

.modal-config-header {
  >.claim-header-inner-modal-platform {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 0 15px 0;

    >div>h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }

    >div>p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  flex-direction: column;
  align-items: flex-start !important;

  >.btn-close {
    color: white !important;
    background-color: #079992 !important;
    position: absolute;
    border-radius: 30px;
    top: 30px;
    right: 50px;
    background: transparent url(../../../../../assets/images/optimisation/close-button.png) center/1em auto no-repeat;
    opacity: 1;
  }
}

// BODY // BODY // BODY // BODY

body .modal-config-body-platform {
  padding: 100px 40px;

  >.background-grey-around {
    background-color: rgba(164, 169, 180, .26);
    padding: 18px 25px;
    border-radius: 15px;
  }
}

.fleet-platform-list-add {
  position: relative;
  display: block;
  background: #f6f8fa;
  border-radius: 13px;
  box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
  min-width: 385px;
  transition: all 0.2s;
  border: 2px solid #079992;

  &:hover {
    box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.23);

    .fr-modal-platform {
      >.fleet-item-title-modal-platform {
        color: #585757 !important;
      }
    }
  }

  .fleet-first-line-tour-management-modal-platform {
    width: 100%;
    display: flex;

    >.hw-modal-platform {
      flex: 0 0 50px;

      >.rounded-background {
        background-color: #079992;
        border: 3px solid #fff;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.137);

        >.span-color {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 20px;
          color: white;
          margin: 0;
          font-size: 12px;
          font-weight: bold;
        }
      }

      @media screen and (max-width: 1620px) {
        flex: 0 0 110px;
      }

      @media screen and (max-width: 1200px) {
        flex: 0 0 110px;
      }

      >.img-illustration-fleet-2-modal-platform {
        position: absolute;
        height: 90px;
        width: auto;
        object-fit: none;
        left: 0px;

        @media screen and (max-width: 1620px) {
          position: absolute;
          top: 13px;
          left: 10px;
          height: 70px;
          width: auto;
        }

        @media screen and (max-width: 1200px) {
          height: 70px;
          top: 13px;
        }
      }
    }

    >.fr-modal-platform {
      margin-left: 10px;
      margin-top: 6px;
      margin-bottom: 10px;

      >.fleet-item-title-id-modal-platform {
        padding: 3px 7px;
        color: #079992;
        background-color: #c4e7e5;
        font-weight: 600;
        font-size: 10px;
        border-radius: 2px;
        text-transform: uppercase;
      }

      flex-grow: 1;

      body .geocoder-wrapper {
        .geocoder {
          position: absolute;
          z-index: 1;
        }

        .mapboxgl-ctrl-geocoder {
          min-width: 100%;
          width: 100% !important;
        }
      }

      .mapboxgl-ctrl-geocoder {
        min-width: 70%;
        border-radius: 15px;
        background-color: #EAEAEA;
        box-shadow: none;
        margin-top: 5px;
      }

      .mapboxgl-ctrl-geocoder--button {
        background-color: #EAEAEA;
      }
    }
  }
}

.msg-error-modal-add-platform {
  font-size: 15px;
  color: red;
}

// FOOTER // FOOTER // FOOTER // FOOTER

.modal-config-footer {
  >.modal-btn-footer {
    height: 60px !important;
    width: 400px !important;
    background-color: #079992 !important;
    border-radius: 11px !important;
    border-bottom: 4px solid #0a8a84 !important;
    color: white !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    text-decoration: underline !important;
    text-decoration-color: #079992 !important;
  }

  >.modal-btn-footer:active {
    border-bottom: 1px solid #06726c !important;
  }

  >.modal-btn-footer:hover {
    background-color: #10a39c !important;
  }

  >.modal-btn-footer:disabled {
    opacity: 0.4;
  }
}