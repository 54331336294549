.card-fleet{
    position:relative;
    height:350px;
    background: white;
    border-radius:25px;
    margin:30px 0 0 0;
    padding: 15px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    overflow:hidden;
}

.card-fleet-test{
    position:relative;
    height:350px;
    background: #ffffff;
    border-radius:25px;
    margin:30px 0 0 0;
    padding: 15px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    overflow:hidden;
}
.card-fleet h4{
    text-align: left;
    font-size: 15px;
    font-family: Montserrat-Bold, sans-serif;
    color: #8b8b8b;
    text-transform:uppercase;
}
.card-fleet .card-fleet-header-2-title{
    text-align: left;
    font-size: 14px;
    margin-left:5px;
    font-family: Montserrat-SemiBold, sans-serif;
    color: #313131;
    text-transform:uppercase;
}
.card-fleet .card-fleet-header-2-button a{
    color:white;
    text-align: center;
    font-size: 10px;
    margin-left:5px;
    font-family: Montserrat-SemiBold, sans-serif;
    background-color: #e55039;
    padding:2px 10px;
    border-radius:10px;
}
.card-fleet .card-fleet-header-2-button a:hover{
    text-decoration:none;
}
.card-fleet-number-container{
    position:relative;
    z-index:2;
    width:100px;
    height: 150px;
    background-color: green;
    border-radius:20px;
    padding:10px;
    color:white;
    margin-left:10px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
}
.card-fleet-number-container-2{
    position:relative;
    z-index:2;
    width:100px;
    height: 115px;
    background-color: green;
    border-radius:20px;
    padding:10px;
    color:white;
    margin-left:10px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.05);
}
.card-fleet-number-container-full-width{
    position:relative;
    z-index:2;
    height: 140px;
    width:100px;
    background-color: green;
    border-radius:20px;
    padding:10px;
    color:white;
    margin-left:10px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.09);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.09);
}

.card-fleet-number-wrapper{
    margin-top:10px;
    display:flex;
    flex-direction:row;
}
.card-fleet-number-container-number{
    font-size:40px;
    display:block;
    font-family: Montserrat-Bold, sans-serif;
}
.card-fleet-number-container-label{
    font-size:13px;
    display:block;
    font-family: Montserrat-SemiBold, sans-serif;

}
.card-fleet-map-view-container{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    top:40%;
    background-color: deepskyblue;
}
.issues-return-container{
    position: relative;
    display: block;
    border-radius: 7px;
    margin: 10px;
    padding: 10px;
    overflow: hidden;
    background-color: rgba(0,0,0,.06);
    color: #525252;
    transition: .1s
}
.issues-return-container .decoration{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:0;
}
.issues-return-container .decoration .left-ticket{
    position: absolute;
    top: 50%;
    left: -4px;
    width:10px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
}
.issues-return-container .decoration .right-ticket{
    position: absolute;
    top: 50%;
    right: -4px;
    width:10px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
}
.issues-return-container .decoration .title-overlay{
    position: absolute;
    top: 0;
    right: 0;
    left:0;
    height: 33px;
    background-color: rgb(7, 153, 146);
}
.issues-return-container .decoration .bgc-overlay{
    position: absolute;
    top: 30%;
    right: 0;
    left:0;
    height:60px;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.036852240896358524) 49%, rgba(0,212,255,0) 100%);
    z-index:0;
}

.issues-return-container-test{
    position: relative;
    display: block;
    border-radius: 5px;
    margin: 10px;
    padding: 4px;
    overflow: hidden;
    background-color: rgba(0,0,0,.03);
    color: #525252;
    background-color: #ffffff;
    padding:10px;
    transition: .1s
}
.issues-return-container:hover{

}
.issues-return-container a:hover, .issues-return-container-test a:hover{
    text-decoration:none;

}
.mapContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.card-fleet-number-container-full-width a, .card-fleet-number-container-2 a, .card-fleet-number-container a, .card-fleet-number-container a:hover, .card-fleet-number-container-full-width a:hover, .card-fleet-number-container-2 a:hover{
    color: #FFFFFF;
    text-decoration: none;
    display: block;
}
.card-fleet-animation-container{
    position:absolute;
    right:0;
    bottom:-15px;
    left:0;
}
.card-fleet-issue-wrapper{
    overflow-y: auto;
    height: 100%;
}
.no-bl{
    font-family: Montserrat-SemiBold, sans-serif;
    font-size:12px;
    color: #7d7d7d;
    margin-left:20px;
}
.tootip-card-fleet{
    position:absolute;
    right: 15px;
    top:15px;
}
.vous{
    background-color: #7d7d7d;
    height: 15px;
    width: 15px;
}
.home{
    background-color: #7d7d7d;
    height: 15px;
    width: 15px;
}
.desc-card > .title{
    font-size: 15px;
    color: #37474f;
    padding-top: 15px;
    margin-bottom: 5px;
}
.desc-card > .desc{
    color: #bfbfbf !important;
    text-transform: capitalize !important;
    margin-top: 10px !important;
    font-size: 11px;
    font-weight: normal;
}
.desc-card > .status{
    font-size: 12px;
    font-weight: 600;
    padding: .25em .4em;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    background-color: #079992;
    color: white;
}
