.kpi {
  background-color: white;
  padding: 19px 10px;
  margin: 2px 5px;
  flex: 1 1 33.3333%;
  display: flex;
  justify-content: flex-start;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
  @media screen and (max-width: 991px) {
    padding: 13px 7px;
  }
  > .kpi-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > .logo-container {
      display: flex;
      border-radius: 13px;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      flex: 0 0 50px;
      @media screen and (max-width: 991px) {
        height: 25px;
        width: 25px;
        flex: 0 0 25px;
        border-radius: 5px;
      }
      .icon-kpi {
        height: 30px;
        width: 30px;
        @media screen and (max-width: 991px) {
          height: 15px;
          width: 15px;
        }
      }
    }
    > .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      padding-left: 11px;
      position: relative;
      > .kpi-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        color: #c4cad1;
        @media screen and (max-width: 991px) {
          font-size: 10px;
        }
      }
      > .kpi-value {
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        color: black;
        padding-bottom: 5px;
        @media screen and (max-width: 991px) {
          font-size: 17px;
        }
      }
    }
  }
}
