.background-modal-fleet-add-truck {
  background: url(../../../../assets/images/background/background_decoration2x.png)
    no-repeat;
  position: absolute;
  background-size: cover;
  right: -280px;
  top: 0px;
  height: 255px;
  width: 600px;
}

// HEADER MODAL // HEADER MODAL // HEADER MODAL // HEADER MODAL

.modal-content {
  border-radius: 20px !important;
  overflow-x: hidden;
}

.modal-add-vehicule-header {
  > .btn-close {
    color: white !important;
    background-color: #079992 !important;
    position: absolute;
    border-radius: 30px;
    top: 30px;
    right: 50px;
    background: transparent
      url(../../../../assets/images/optimisation/close-button.png) center/1em
      auto no-repeat;
    opacity: 1;
  }

  > .claim-header-inner-modal-add-veh {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    text-align: left;
    margin: 15px 0 15px 0;

    > div > h5 {
      font-weight: bold;
      line-height: 1;
      margin-bottom: 5px;
      font-size: 17px;
    }

    > div > p {
      color: #8b8b8b;
      font-weight: 600;
      line-height: 1;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}

// MODAL BODY 1 // MODAL BODY 1 // MODAL BODY 1 // MODAL BODY 1

.modal-consult-reserve-body {
  padding: 0 0 70px 0;

  .modal-span-title {
    font-size: 11px;
    color: #adb8c2;
    font-weight: 500;
  }

  .modal-span-desc {
    margin-top: 10px;
    font-size: 11px;
    color: #354a5f;
    font-weight: 600;
    line-height: 1.1;
  }

  .modal-input-col-left {
    background-color: #f1f5f8;
    height: 45px;
    margin-top: 5px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e8ecf4;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-size: 11px;
    color: #354a5f;
    font-weight: 600;
  }

  .modal-input-col-left::placeholder {
    color: #cbd0d3;
  }

  .modal-col-left {
    > .row > .modal-div-center {
      > .div-margin-bottom {
        margin-bottom: 20px;
      }

      > .modal-div-background-btn {
        background-color: #eaeaea;
        margin-top: 10px;
        border-radius: 15px;
        border: 1px solid #eaeaea;

        > .modal-btn {
          background-color: white;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 500;
          min-height: 50px;
          margin: 5px;
        }

        > .modal-btn-desactive {
          background-color: transparent;
          border-radius: 5px;
          min-height: 50px;
          font-size: 12px;
          font-weight: 500;
          color: #aeb2b4;
          margin: 5px;
        }
      }
    }
  }

  // CODE POUR LA COL DU MILLIEU QUI N'EST PAS ENCORE ACTIF

  .modal-col-middle {
    > .modal-separator {
      margin-top: 10px;
      height: 100%;
      width: 1px;
      border: 1px #f1f1f1 solid;
    }
  }

  // CODE POUR LA COL DE DROITE QUI N'EST PAS ENCORE ACTIF

  .modal-col-right {
    > .container-background {
      background-color: #f1f5f8;
      border-radius: 10px;
      height: 86%;
      padding: 5px 5px 5px 5px;

      > .vehicule-props {
        color: #079992;
        background-color: #c4e7e5;
        font-weight: 600;
        display: inline-block;
        padding: 8px 10px;
        font-size: 10px;
        border-radius: 2px;
        text-transform: uppercase;
        margin: 5px 3px;
      }
    }
  }

  .fleet-background-modal-1 {
    height: 20px;
    width: 20px;
    font-size: 10px;
    border-radius: 6px;
    flex: 0 0 auto;
  }

  .fleet-text-statut {
    flex: 1 1 auto;
    font-size: 11px;
    color: black;
    font-weight: 500;
  }
}

// MODAL BODY 2 // MODAL BODY 2 // MODAL BODY 2 // MODAL BODY 2

.modal-add-vehicule-body-2 {
  padding: 30px 80px !important;
  margin-bottom: 30px;

  > .container-top-modal-2 {
    position: relative;
    display: block;
    border-radius: 13px;
    overflow: hidden;
    min-width: 155px;

    .container-flex {
      width: 100%;
      display: flex;
      padding: 15px 15px;
    }

    > .container-flex > .l-side {
      flex: 0 0 135px;
      margin-left: 113px;

      @media screen and (max-width: 1620px) {
        margin-left: 77px;
      }
    }

    .container-separator-2 {
      flex: 0 0 10px;
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      margin-left: 40px;

      > .separator-2 {
        width: 1px;
        height: 20px;
        background-color: #f3f3f3;
        margin-right: 50px;
      }

      @media screen and (max-width: 1200px) {
        margin-left: 29px;
      }
    }

    .icon-small {
      height: 12px;
      color: #a0a3a5;
    }

    .span-small {
      color: #a0a3a5;
      font-size: 10px;
      text-transform: uppercase;
    }

    .r-side-2 {
      flex: 0 0 108px;
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      @media screen and (max-width: 1200px) {
        margin-left: -50px;
      }
    }

    .container-separator-1 {
      flex: 0 0 10px;
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      margin-left: 220px;

      > .separator-1 {
        width: 1px;
        height: 20px;
        background-color: #f3f3f3;
        margin-right: 50px;
      }

      @media screen and (max-width: 1620px) {
        margin-right: 77px;
      }

      @media screen and (max-width: 1200px) {
        margin-left: 10px;
        margin-right: -13px;
      }
    }
  }

  > .container-color-modal-2 {
    background-color: #ecedef;
    border-radius: 10px;
    padding: 10px 15px;
    height: 400px;
    display: flex;
    min-width: 0;
    flex-direction: column;

    .border-dashed {
      border: 2px #a4a9b4 dashed;
      border-radius: 10px;
      height: 55px;
      width: 55px;

      > .rounded-background {
        border-radius: 50px;
        height: 25px;
        width: 25px;
        background-color: #c4e7e5;

        > .span-color {
          color: #1ea29c;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    > div > .fleet-button-add-modal-2 {
      display: flex;
      align-items: center;

      > .btn-icon-more-platform-modal-2 {
        color: #84868d;
        margin-right: 10px;
      }

      > .fleet-span-text-modal-2 {
        color: #84868d;
        font-weight: 600;
        font-size: 13px;
      }

      &:hover {
        background-color: #e3e4e8;
      }
    }

    .modal-input-modal-2 {
      background-color: #eaeaea;
      height: 30px;
      width: 80px;
      border-radius: 17px;
      border: 1px solid #eaeaea;
      font-weight: 500;
      font-size: 13px;
      text-align: center;
      margin-right: 63px;

      @media screen and (max-width: 1200px) {
        margin-right: 14px;
      }
    }

    input[type="number"]::-webkit-inner-spin-button {
      opacity: 1;
    }

    input[type="number"]:hover::-webkit-inner-spin-button {
      background-color: blue;
    }

    .modal-input-modal-2::placeholder {
      color: #cbd0d3;
    }

    .fleet-item-tour-compartiment-list-part {
      flex: 1 1 auto;

      height: 100%;
      width: 100%;
      min-height: 0;
      overflow-y: hidden;
      position: relative;
    }

    .fleet-item-tour-compartiment-add-part {
      flex: 0 0 auto;
    }

    .fleet-item-tour-compartiment-horizontal-divider {
      width: 100%;
      min-width: 100%;
      margin: 10px 0;
      box-sizing: border-box;
      padding: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 7px;
        background: rgb(236, 237, 239);
        background: linear-gradient(
          0deg,
          rgba(236, 237, 239, 1) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        position: absolute;
        top: -17px;
        left: 0;
        right: 0;
      }
    }

    .fleet-item-tour-compartiment-modal-2 {
      position: relative;
      display: block;
      background: white;
      border-radius: 13px;
      margin: 10px 0;
      box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
      overflow: hidden;
      min-width: 155px;

      &.add {
        border: 2px solid #079992;
      }

      &:hover {
        .fleet-btn-add-address {
          display: inline-block !important;
        }
      }

      .fleet-first-line-tour-compartiment-modal-2 {
        width: 100%;
        display: flex;
        padding: 8px 13px;
      }

      > .fleet-first-line-tour-compartiment-modal-2 > .hw-modal-2 {
        flex: 0 0 105px;

        @media screen and (max-width: 1620px) {
          flex: 0 0 70px;
        }

        @media screen and (max-width: 1200px) {
          flex: 0 0 50px;
        }
      }

      .hwe-1-modal-2 {
        flex: 0 0 100px;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        @media screen and (max-width: 1200px) {
        }
      }

      .hwe-2-modal-2 {
        flex: 0 0 100px;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        @media screen and (max-width: 991px) {
          padding-right: 0px;
        }
      }

      .hwe-3-modal-2 {
        flex: 0 0 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          .fleet-btn-suppr {
            display: inline-block !important;
          }
        }

        > .fleet-btn-suppr {
          color: #e55039;

          &.toggleable {
            display: none;
          }

          > .fleet-icon-trash {
            height: 12px;
          }

          &:active {
            border-bottom: 0 solid transparent;
          }
        }

        > .fleet-btn-add-container {
          background-color: #079992;
          padding: 12px 20px 12px 20px;
          border-radius: 14px;
          color: white;
          border-bottom: 3px solid #0a8a84;
          font-size: 11px;

          > .btn-icon-more-platform {
            color: #079992;
            margin-right: 10px;
          }

          &:active {
            border-bottom: 0 solid transparent;
          }
        }

        @media screen and (max-width: 991px) {
          padding-right: 0px;
        }
      }

      .fr-modal-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > .fleet-item-title-id-modal-2 {
          color: #079992;
          background-color: #c4e7e5;
          font-weight: 600;
          padding: 1px 8px;
          font-size: 10px;
          border-radius: 2px;
          text-transform: uppercase;
        }

        @media screen and (max-width: 1200px) {
          margin-left: 20px;
        }

        flex-grow: 1;
        /* default 0 */
      }
    }
  }
}

// MODAL BODY 3 // MODAL BODY 3 // MODAL BODY 3 // MODAL BODY 3

.modal-add-vehicule-body-3 {
  padding: 50px 170px;
  height: 500px;

  > .platform-list-container {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    min-height: 0;
    overflow-y: hidden;
    position: relative;
    align-items: center;

    .fleet-platform-list-modal-3 {
      position: relative;
      display: block;
      background: #f6f8fa;
      border-radius: 13px;
      margin-bottom: 10px;
      box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
      overflow: hidden;
      min-width: 385px;
      transition: all 0.2s;

      &.disabled {
        opacity: 0.5;
        transform: scale(0.98);
      }

      .fleet-first-line-tour-management-modal-3 {
        width: 100%;
        display: flex;
        padding: 10px 13px;
        cursor: pointer;
      }

      > .fleet-first-line-tour-management-modal-3 > .hw-modal-3 {
        flex: 0 0 10px;
        margin-top: 3px;

        > .rounded-background {
          background-color: #079992;
          border: 3px solid #fff;
          border-radius: 50%;
          width: 27px;
          height: 27px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.137);
          cursor: pointer;

          > .span-color {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 20px;
            color: white;
            margin: 0;
            font-size: 12px;
            font-weight: bold;
          }
        }

        @media screen and (max-width: 1620px) {
          flex: 0 0 70px;
        }

        @media screen and (max-width: 1200px) {
          flex: 0 0 50px;
        }

        > .img-illustration-fleet-2-modal-3 {
          position: absolute;
          height: 80px;
          width: auto;
          object-fit: none;
          left: 0px;

          @media screen and (max-width: 1620px) {
            position: absolute;
            width: auto;
            left: 0px;
          }

          @media screen and (max-width: 1200px) {
            height: 80px;
            width: auto;
            object-fit: none;
            left: 0px;
          }
        }
      }

      .fr-modal-3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 20px;

        @media screen and (max-width: 1620px) {
          margin-left: 60px;
        }

        > .fleet-item-title-id-modal-3 {
          color: #079992;
          background-color: #c4e7e5;
          font-weight: 600;
          padding: 1px 8px;
          font-size: 10px;
          border-radius: 2px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        > .fleet-item-title-modal-3 {
          white-space: nowrap;
          text-align: left;
          display: block;
          font-size: 16px;
          line-height: 1;
          font-weight: 700;
          color: #333333;

          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }
        }

        > .fleet-item-address-modal-3 {
          display: block;
          text-align: left;
          font-size: 11px;
          line-height: 1;
          font-weight: 600;
          color: #333333;

          @media screen and (max-width: 1200px) {
            font-size: 10px;
          }
        }

        flex-grow: 1;
        /* default 0 */
      }
    }
  }

  > .fleet-item-tour-compartiment-horizontal-divider-modal-3 {
    width: 98%;
    min-width: 98%;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 7px;
      background: rgb(236, 237, 239);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      position: absolute;
      top: -22px;
      left: 0;
      right: 0;
    }
  }
}

// MODAL 4 // MODAL 4 // MODAL 4 // MODAL 4

.modal-add-vehicule-body-4 {
  padding: 100px 130px;

  > .container-text {
    margin-top: 40px;
    margin-bottom: 40px;

    > .title-verif {
      color: black;
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 0px;
    }

    > .msg-verif {
      color: #a9a9a9;
      font-size: 12px;
      font-weight: bold;
    }
  }

  > .container-background {
    background-color: #e7e9ec;
    padding: 20px 20px;
    border-radius: 15px;

    > .fleet-item-tour-management-modal-4 {
      position: relative;
      display: block;
      background: white;
      border-radius: 13px;
      box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.15);
      overflow: hidden;
      min-width: 385px;
      transition: all 0.2s;

      &.disabled {
        opacity: 0.5;
        transform: scale(0.98);
      }

      &:hover {
        // background: #fcfcfc;
        box-shadow: 0px 1px 1px 0px rgba(14, 42, 90, 0.23);

        .fr-modal-4 {
          > .fleet-item-title-modal-4 {
            color: #585757 !important;
          }

          > .fleet-item-desc-modal-4 {
            color: #646363;
          }
        }
      }

      .fleet-item-step-optimization-container-tr-modal-4 {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 17px;
          bottom: 17px;
          left: 119px;
          width: 3px;
          border-left: 2px dashed #e9ecef;
          z-index: 0;

          @media screen and (max-width: 1620px) {
            left: 59px;
          }
        }
      }

      .fleet-first-line-tour-management-modal-4 {
        width: 100%;
        display: flex;
        padding: 10px 15px;
      }

      > .fleet-first-line-tour-management-modal-4 > .hw-modal-4 {
        flex: 0 0 105px;

        @media screen and (max-width: 1620px) {
          flex: 0 0 70px;
        }

        @media screen and (max-width: 1200px) {
          flex: 0 0 50px;
        }

        > .img-illustration-fleet-2-modal-4 {
          position: absolute;
          top: -20px;
          left: -10px;
          height: 100px;
          width: auto;

          @media screen and (max-width: 1620px) {
            position: absolute;
            top: -10px;
            left: -10px;
            height: 80px;
            width: auto;
          }

          @media screen and (max-width: 1200px) {
            top: -10px;
            left: -10px;
            height: 70px;
          }
        }
      }

      // CODE POUR LES BADGES

      // .hwe-auto-modal-4 {
      //   flex: 0 0 200px;
      //   display: flex;
      //   align-items: start;
      //   flex-wrap: wrap;
      //   justify-content: end;
      //   align-content: center;

      //   >.fleet-info-truck-modal-4 {
      //     color: #079992;
      //     background-color: #c4e7e5;
      //     font-weight: 600;
      //     padding: 1px 8px;
      //     font-size: 10px;
      //     border-radius: 2px;
      //     text-transform: uppercase;
      //     margin: 2px 2px;
      //   }

      //   @media screen and (max-width: 991px) {
      //     padding-right: 0px;
      //   }
      // }

      .fr-modal-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > .fleet-item-title-id-modal-4 {
          color: #079992;
          background-color: #c4e7e5;
          font-weight: 600;
          padding: 1px 8px;
          font-size: 10px;
          border-radius: 2px;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        > .fleet-item-title-modal-4 {
          white-space: nowrap;
          text-align: left;
          display: block;
          font-size: 16px;
          line-height: 1;
          font-weight: 700;
          color: #333333;
          margin-bottom: 0;

          @media screen and (max-width: 1200px) {
            font-size: 13px;
          }
        }

        > .fleet-item-desc-modal-4 {
          display: block;
          text-align: left;
          font-size: 11px;
          line-height: 1;
          font-weight: 500;
          text-transform: uppercase;
          color: #333333;

          @media screen and (max-width: 1200px) {
            font-size: 10px;
          }
        }

        flex-grow: 1;
        /* default 0 */
      }
    }
  }
}

// MODAL FOOTER // MODAL FOOTER // MODAL FOOTER // MODAL FOOTER

.modal-answer-reserve-footer {
  min-height: 88.5px;
  > .modal-btn-footer {
    width: 400px !important;
    background-color: #079992 !important;
    border-radius: 11px !important;
    border-bottom: 4px solid #0a8a84 !important;
    color: white !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    margin-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  > .modal-btn-footer:active {
    border-bottom: 1px solid #06726c !important;
  }

  > .modal-btn-footer:hover {
    background-color: #10a39c !important;
  }

  > .modal-btn-footer:disabled {
    opacity: 0.4;
  }

  > .modal-btn-footer-back {
    height: 60px !important;
    width: 300px !important;
    background-color: white !important;
    border-radius: 11px !important;
    border: #d0d0d0 1px solid;
    color: #787878 !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-right: 10px;
  }

  > .modal-btn-optimization {
    height: 60px !important;
    width: 300px !important;
    background-color: white !important;
    border-radius: 11px !important;
    border: #d0d0d0 1px solid;
    color: #787878 !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-right: 10px;
  }
}

.error {
  color: #e55039;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1;
  min-height: 0.7rem;
}

.error-body {
  margin: auto;
  color: #e55039;
  font-weight: 600;
  font-size: 0.8rem;
}
